<template>
  <div id="user">
    <Header :items="items" path="user" :customHeaderTitle="customHeaderTitle" />
    <router-view ref="view" />
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  components: { Header },
  data() {
    return {
      items: [
        {
          label: this.$t('sidebar.myStays'),
          route: 'Stays'
        },
        {
          label: this.$t('sidebar.myOrders'),
          route: 'Orders'
        },
        {
          label: this.$t('sidebar.myMessages'),
          route: 'Messages'
        }
      ],
      customHeaderTitle: null
    }
  },
  methods: {
    updateCustomHeaderTitle() {
      let interval = setInterval(() => {
        if(!this.$refs.view) return
        clearInterval(interval)
        this.customHeaderTitle = this.$refs.view.customHeaderTitle
      }, 100)
    }
  },
  updated() {
    this.updateCustomHeaderTitle()
  }
}
</script>

<style lang="scss">
  #user {
    padding-top: 100px;
  }
</style>