<template>
  <div id="header">
    <md-icon class="back" @click.native="back">arrow_back</md-icon>

    <div class="title" v-show="screenWidth >= 270 && title">{{ title }}</div>

    <div class="items" v-if="$full">
      <div
        class="item"
        v-for="(item, key) in items"
        :key="key"
        :class="{ active: item.route == $route.name.split('/')[1] }"
        @click="go(item.route)"
      >
        <div class="label">{{ item.label }}</div>
      </div>
    </div>
    <div class="menu" v-if="menuOpened">
      <div class="items">
        <div
          class="item"
          v-for="(item, key) in items"
          :key="key"
          :class="{ active: item.route == $route.name.split('/')[1] }"
          @click="
            go(item.route)
            menuOpened = false
          "
        >
          <div class="label">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="menu-button" v-if="!$full" @click="menuOpened = !menuOpened">
      <md-icon>menu</md-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items', 'path', 'customHeaderTitle'],
  data() {
    return {
      menuOpened: false,
      screenWidth: window.innerWidth,
    }
  },
  computed: {
    title() {
      let title = this.customHeaderTitle
      this.items.forEach(item => {
        if (item.route == this.$route.name.split('/')[1]) title = item.label
      })
      return title
    },
  },
  methods: {
    go(route) {
      this.$router.replace(`/${this.path}/` + route.toLowerCase())
      this.$store.routerHistory.pop()
    },
    back() {
      if (this.$store.routerHistory.length == 0) this.$router.push('/')
      else
        this.$router.push(this.$store.routerHistory.pop().fullPath).then(() => {
          this.$store.routerHistory.pop()
        })
    },
    _resize() {
      this.screenWidth = window.innerWidth
    },
  },
}
</script>

<style lang="scss">
#header {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  background-color: white;
  box-shadow: 0px 2px 2px 0px rgba(150, 159, 170, 0.5);
  z-index: 1;

  .back {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    height: 35px;
    cursor: pointer;
    transition: 0.2s all;
    filter: invert(50%) sepia(37%) saturate(7292%) hue-rotate(166deg)
      brightness(0%) contrast(105%);
    min-width: 30px;
    font-size: 30px !important;

    &:hover {
      filter: invert(50%) sepia(37%) saturate(7292%) hue-rotate(166deg)
        brightness(60%) contrast(105%);
    }
  }
  .title {
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 19px;
    max-width: 50%;
  }
  > .items {
    position: absolute;
    right: 0px;
    height: 100%;

    .item {
      position: relative;
      display: inline-block;
      height: 100%;
      background-color: white;
      transition: 0.2s all;
      cursor: pointer;
      color: #47525e;
      font-family: Roboto;
      font-size: 18px;
      text-align: center;
      line-height: 60px;
      padding: 0 15px 0 15px;

      &:hover {
        background-color: #e5e9f2;
      }
      &.active {
        background-color: #e5e9f2;
      }
    }
  }
  .menu-button {
    position: absolute;
    z-index: 21;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 0.2s all;
    filter: invert(50%) sepia(37%) saturate(7292%) hue-rotate(166deg)
      brightness(0%) contrast(105%);
    &:hover {
      filter: invert(50%) sepia(37%) saturate(7292%) hue-rotate(166deg)
        brightness(60%) contrast(105%);
    }
  }
  .menu {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;

    .items {
      width: 100%;
      height: 100%;

      .item {
        background-color: white;
        transition: 0.2s all;
        cursor: pointer;
        color: #47525e;
        font-family: Roboto;
        font-size: 18px;
        text-align: center;
        line-height: 60px;
        padding: 0 15px 0 15px;

        &:hover {
          background-color: #e5e9f2;
        }
        &.active {
          background-color: #e5e9f2;
        }
      }
    }
  }
}
</style>
